import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import { Link } from "gatsby";
// import SEO from "../../components/SEO";
import { useState } from "react";
import { FaSpinner } from "react-icons/fa";

const OrderFailure = props => {
  return (
    <Layout processed={true}>
      <main className="error-page">
        <div className="error-container">
          <>
            <h1>Oops!</h1>
            <p>There was a problem processing your order, please contact admin@depthlearning.co.uk</p>
            <Link to="/" className="btn">
              Homepage
            </Link>
          </>
        </div>
      </main>
    </Layout>
  );
};

export default OrderFailure;
